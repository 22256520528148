import React from 'react'
import { graphql } from 'gatsby';
import Layout from '@components/layout'
import CaseStudiesMore from '@components/case-studies-more'
import FacebookShare from '@components/fb-share-button';
import TwitterShare from '@components/tw-share-button';

export default function prisma(props) {
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = '/images/prisma-case1.png'
  const thumbAltText = 'prisma case study'
  const description = 'Prisma is an innovative online school for 4-8th graders who want an education tailored to their interests, abilities, and goals for the future. WyeWorks is the engineering team who built Prisma LIVE from scratch, a live learning platform purpose-built for kids.'
  const shareUrl = siteUrl + props.location.pathname
  const caseTitle = 'WyeWorks made Prisma Live a reality.'

  return (
    <Layout
      location={props.location}
      title='Prisma'
      namespace='page'
      description={description}
      thumbnail={thumbnail}
    >
      <div className='hero hero-2'>
        <div className='hero-2__background'></div>
        <div className='hero-2__container'>
          <div className='case-hero__wrapper'>
            <div className='case-hero__pattern'>
              <img src='/images/case-hero-pattern.png' alt='' />
            </div>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Case Study
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='case-hero__hero-block'>
              <div className='case-hero__hero-block-wrapper'>
                <div className='case-hero__case-logo'>
                  <svg width="168" height="56" viewBox="0 0 168 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M150.578 15.765L168 43.5798H133.156L150.578 15.765ZM150.578 27.5696L157.121 37.4546H144.037L150.578 27.5696Z" fill="white"/>
                    <path d="M126.479 18.7543L126.472 18.7486C126.472 18.7486 126.397 18.6751 126.404 18.6818C124.161 16.5493 121.235 15.359 118.154 15.318H117.971C114.512 15.3228 111.382 16.766 109.149 19.0798L108.804 19.4368L108.44 19.0568L108.121 18.7543L108.113 18.7486C108.113 18.7486 108.039 18.6751 108.045 18.6818C105.802 16.5493 102.877 15.359 99.7954 15.318H99.6121C92.8522 15.3266 87.3455 20.8323 87.3379 27.5922V43.5747H93.528V27.5903C93.528 24.2361 96.257 21.5071 99.6111 21.5071C102.958 21.5071 105.682 24.2237 105.695 27.5674V43.5747H111.887V27.5903C111.887 24.2361 114.616 21.5071 117.97 21.5071C121.317 21.5071 124.041 24.2237 124.054 27.5674V43.5747H130.245V27.5807C130.245 24.2742 128.934 21.2713 126.806 19.0645L126.799 19.0568L126.479 18.7543Z" fill="white"/>
                    <path d="M80.9451 15.318H70.1762C65.4294 15.318 61.5664 19.181 61.5664 23.9278C61.5664 28.6756 65.4294 32.5375 70.1762 32.5375H75.1626C76.4942 32.5375 77.5766 33.62 77.5766 34.9515C77.5766 36.2821 76.4942 37.3646 75.1626 37.3646H64.3937V43.5556H75.2867C79.9887 43.4907 83.7953 39.6049 83.7629 34.8943C83.7314 30.1837 79.8732 26.3494 75.1626 26.3484H70.1762C68.8418 26.3484 67.7574 25.2632 67.7574 23.9287C67.7574 22.5953 68.8418 21.5109 70.1762 21.5109H80.9451V15.318Z" fill="white"/>
                    <path d="M57.7344 15.4069H51.5439V43.6028H57.7344V15.4069Z" fill="white"/>
                    <path d="M57.7344 0H51.5439V6.65467H57.7344V0Z" fill="white"/>
                    <path d="M48.5161 16.8271C46.9564 15.8717 45.1476 15.339 43.3102 15.318C43.3102 15.318 43.1536 15.318 43.1546 15.318C42.2525 15.318 41.3543 15.4355 40.4838 15.6693L40.4428 15.6807C36.0195 16.9053 32.9335 20.9573 32.9307 25.5505V43.5757H39.1284L39.2553 24.6647C39.2563 24.5587 39.2725 24.4528 39.304 24.3517V24.3506C39.662 23.1995 40.5048 22.2755 41.6178 21.8155C42.3327 21.5186 43.0954 21.4394 43.8237 21.5597C43.8237 21.5597 44.1635 21.616 44.216 21.6246C44.2208 21.6255 44.2255 21.6265 44.2294 21.6274C44.2313 21.6274 44.2322 21.6274 44.2341 21.6284C44.3334 21.6541 44.4308 21.6847 44.5272 21.7191C44.7458 21.7964 44.9586 21.8938 45.1619 22.0093C45.1629 22.0102 45.1638 22.0102 45.1648 22.0111C45.1686 22.013 45.1724 22.0159 45.1762 22.0188C45.1925 22.0302 45.2373 22.0617 45.2373 22.0617L48.5161 16.8271Z" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.21968 55.9998V41.1846L6.94033 41.6122C9.04697 42.8607 11.5049 43.5776 14.1289 43.5776C21.9273 43.5776 28.2587 37.2463 28.2587 29.4478C28.2587 21.6494 21.9273 15.318 14.1289 15.318C6.3304 15.318 0 21.6494 0 29.4478C0 29.6168 0.00286031 29.7857 0.00858745 29.9623V55.9998H6.21968ZM14.1289 21.529C9.75905 21.529 6.21108 25.078 6.21108 29.4478C6.21108 33.8176 9.75905 37.3655 14.1289 37.3655C18.4996 37.3655 22.0476 33.8176 22.0476 29.4478C22.0476 25.078 18.4996 21.529 14.1289 21.529Z" fill="white"/>
                  </svg>
                </div>
                <h3 className='case-hero__case-title'>
                  {caseTitle}
                </h3>
                <p className='case-hero__description'>
                  {description}
                </p>
              </div>
              <div className='case-hero__hero-block-background'>
                <div className='case-hero__hero-block-gradient'></div>
                <img src='/images/prisma-case-card-tall-bg.png' alt='' />
              </div>
            </div>
            <div className='case-hero__case-meta'>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Services Used
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Fullstack Development
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Industry
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  Education
                </li>
              </ul>
              <ul className='case-meta__list'>
                <li className='case-meta__list-item'>
                  <div className='case-meta__title'>
                    Timeline
                  </div>
                </li>
                <li className='case-meta__list-item'>
                  1 year
                </li>
              </ul>
              <div className='social-layout-1'>
                <FacebookShare url={shareUrl} />
                <TwitterShare url={shareUrl} text={caseTitle.replace('WyeWorks', '@WyeWorks')} />
                <div className='social-layout-1__seperator'></div>
                Share This Case
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='long-content__wrapper'>
          <p>
            Victoria and Alain, two repeat founders whose most recent venture was bought by Google, started Prisma with the mission to prepare kids to thrive in our rapidly changing world. For that purpose they have designed a new category in K-12 education, the Connected Learning Network, which is built from the ground up to not only prepare kids in the core competencies they’ll need for the 21st century but to instill in them a desire to make a large, positive impact on the world.
          </p>
        </div>
      </section>
      <section className='section--full-right'>
        <div className='image-90__wrapper'>
          <img src={thumbnail} alt={thumbAltText} />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The solution
          </h3>
          <p>
            We started the project first by doing a lot of listening and understanding. The most critical aspect was creating a product that was intuitive and fun for the kids, in order to achieve the engagement needed to learn and collaborate together virtually. With that in mind, we then moved to a research phase to determine the state of the art in terms of video transmission, to ensure we were able to create a platform that was geared towards kids but at the same time robust and performant enough to handle a virtual classroom full of students. In just a few months we were able to launch the product for the first cohort of kids.
          </p>
          <p>
            We assembled a team that was able to move fast with the requirements, knowledgeable in the technologies to provide proper guidance on what was possible to do, and with great attention to detail so that they could jell seamlessly with product managers and designers to achieve the beautiful and high quality product we see today.
          </p>
          <p>
            Several were the technical challenges that we faced, like having breakout rooms or concurrent live editing for the lessons as the most notorious ones. Being able to sort them out one after the other helped gain confidence in what the team is capable of, paving the way to build the foundation for a strong partnership that will hopefully last for years to come.
          </p>
          <div className='quote-block'>
            <div className='quote-block__primary-offset'></div>
            <div className='quote-block__quote-wrapper'>
              <div className='quote-block__quote'>
                "I was looking for a solid engineering team to build the foundations of Prisma, and WyeWorks was the perfect fit. They made our vision their own and achieved what seemed impossible a reality. I couldn't be happier for having them onboard."
              </div>
              <div className='quote-block__provider-title'>
                Alain Chuard
                <br />
                Founder & President
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section--full-left'>
        <div className='image-90__wrapper'>
          <img src='/images/prisma-case2.png' alt='' />
        </div>
      </section>
      <section>
        <div className='long-content__wrapper'>
          <h3>
            The results
          </h3>
          <p>
            We've been able to launch the first prototype of Prisma Live to the first cohort of students, and feel ecstatic about it. That was achieved thanks to creating the kind of engineering culture and processes that allows the team to move fast, be nimble and deliver high quality results. After seeing the response of both kids and teachers we know the right choices were made along the way.
          </p>
          <p>
            As we continue our partnership, we are now in the journey of building a suite of world-class EdTech products that are tightly integrated with their curriculum, learners, educators, and community.
          </p>
        </div>
      </section>

      <CaseStudiesMore cases={['wildfire', 'packlane']} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
